.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: #f7f7f7;
    font-family: Arial, sans-serif;
  }
  
  .login-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-container input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .login-container button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }
  
  .login-container p {
    margin-top: 10px;
    color: red;
    font-size: 14px;
  }
  
  